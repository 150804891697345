<template lang="html">
  <v-container>
    <v-row fluid>
      <v-col cols="12" md="3">
        <v-date-field label="Begin Date" v-model="beginDate"></v-date-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-date-field label="End Date" v-model="endDate"></v-date-field>
      </v-col>
      <v-col>
        <v-btn @click="print">แสดงรายการ</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="invoiceData">
      <v-col cols="12">
        <v-data-table :items="invoiceData" :headers="headers">
          <template v-slot:item.created_at="props">
            <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
          </template>
          <template v-slot:item.patient.name_th="{item}">
            <v-label-name :name-data="item.patient.name_th"></v-label-name>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from 'tantee-common/lib/api'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    beginDate: undefined,
    endDate: undefined,
    headers: [
      {
        text: 'Invoice Date',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'Invoice ID',
        value: 'invoiceId',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'จำนวนเงิน',
        value: 'amount',
        class: 'body-2'
      },
      {
        text: 'ค่ายา',
        value: 'category_03',
        class: 'body-2'
      },
      {
        text: 'ค่า Lab',
        value: 'category_07',
        class: 'body-2'
      },
      {
        text: 'ค่าหัตถการ',
        value: 'category_11',
        class: 'body-2'
      },
      {
        text: 'ค่าบริการ',
        value: 'category_12',
        class: 'body-2'
      },
    ],
    invoiceData: undefined,
  }),
  methods: {
    print() {
      let loadingPromise = api.post(['Reporting','ReportAccoutingController','getHDUnitInvoice'],{beginDate: this.beginDate, endDate: this.endDate}).then((returnData)=>{
        if (returnData.success) {
          this.invoiceData = returnData.data
        }
      })
      this.$loading('Loading',loadingPromise)
    }
  },
}
</script>

<style lang="css" scoped>
</style>
